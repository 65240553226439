import "./editor.scss"
import "../css/style.scss"

// Our modules / classes
// import MobileMenu from "./modules/MobileMenu"

// Instantiate a new object using our modules/classes
// const mobileMenu = new MobileMenu()

import ImagesLoaded from "imagesloaded"
import Lazyload from "lazyload"
import Swiper from "swiper/bundle"
// import Swiper, { Navigation } from "swiper/bundle"

// Our modules / classes
import LoadMorePosts from "./modules/LoadMorePosts"
import lazyload from "lazyload"
let loadMorePosts

var debug = true
const mobileBreakpoint = 701
let mobileView = false

let projectsSwiper // vertical swiper holding all projects as horizontal swipers

const ScrollDelta = 10 // how many pixels to scroll to call scroll dependent function

let adminBarHeight = 0
let siteTitleHeight = 0
let titleHeight = 0
let navHeight = 0

let historyUpdate = false // to push to history or not
let autoplayOn = true
let autoplaySpeed = 800
let autoplayDelay = 3000
let orientationLandscape = true
let shuffleInterval
let startAutoShuffleTimeout

let lastScroll = 0
let prevScrollDelta = 0
let scrollYPositions = {}

let currentContentID = -1
let prevContentID = -1
let navOpen = false // initially closed
let navChanging = false
let navChangingTimeout = null
const navChangingTime = 400
let autoScrolling = false

let requestAllPartsInterval = null
let nowWithKeyboard = false // if true mobile keyboard is showing
let observer

let category

let touchStartX
let touchStartY

let gallerySwiperFullscreen = false
let noHistory = false

// console.log("device pixel ratio", window.devicePixelRatio)

jQuery(function ($) {
  // if (debug) console.log("document ready!")
  const $html = $("html")
  $html.addClass("js") // js is working

  if (checkFlexGap()) {
    document.documentElement.classList.add("flexbox-gap")
  } else {
    document.documentElement.classList.add("no-flexbox-gap")
  }

  // console.log("nonce", localized.nonce, localized.nonce_field);
  if ("scrollRestoration" in history) {
    history.scrollRestoration = "manual"
  }
  // for ios touch -> enable active state for links
  document.addEventListener(
    "touchstart",
    function () {
      // console.log("touchstart")
    },
    false
  )

  // const DateTime = luxon.DateTime;

  let pathname = window.location.pathname
  // console.log('pathname', pathname);
  // console.log('history state', history.state);

  const blogName = document.querySelector('meta[name="name"]').content
  const blogDescription = document.querySelector('meta[name="description"]').content

  // console.log(blogName, blogDescription);

  // -----------------------------------------------
  const closeGoHome = $(".go-home")
  const infoButton = $(".info-btn")
  const siteContainer = $(".site-container")
  const siteHeader = $(".site-header")
  const navContainer = $(".site-menu")

  const siteTitle = $(".site-header__title")
  const siteTitleLink = $(".site-header__link")
  const menuItems = $(".menu-main .menu-item")
  const menuNavLinks = $(".menu-main > li.menu-item > a")
  const filterButton = $(".top-left-filter-btn")
  const filterMenu = $(".filter-menu")

  const contentContainer = $(".content-container")

  // -----------------------------------------------------------------------------------------------------

  const $body = $("body")
  const $mainMenu = $(".menu-main")
  const homeID = $body.data("home-id").toString() // post id of the home page as string

  setLandscape()
  touchSupport()

  // set background color from customizer
  document.documentElement.style.setProperty("--bgcolor", $("body").data("bgcolor"))

  calcVhPropertyFromClientHeight() // for mobile viewport height on ios
  setAdminBarHeight()
  navAndTitleHeight()
  checkMobileView()

  // hide and fade in nav and content elements with visibility hidden
  contentContainer.hide()
  contentContainer.css("visibility", "visible")

  if ($body.hasClass("home")) {
    // nav only displayed on home page
    // navContainer.hide()
    // navContainer.css("visibility", "visible")
    // navContainer.fadeIn(400)
  } else {
    contentContainer.fadeIn()
  }

  setTimeout(() => {
    // safety
    navAndTitleHeight()
  }, 100)

  const postsContainer = $(".posts-container")
  let postItemContainers = $(".post-item-container")

  postItemContainers.hide() // hide all post items

  // click or touch on home start container -> go to archive
  siteContainer.on("click", function (e) {
    if (siteContainer.hasClass("page-home")) {
      // console.log("home clickkkk", e)
      siteTitleLink.trigger("click")
    }
  })

  // const homeContainer = document.querySelector(".site-container")
  // if (homeContainer) {
  //   homeContainer.addEventListener(
  //     "touchend",
  //     function (e) {
  //       if (homeContainer.classList.contains("page-home")) {
  //         // console.log("touchend", e)
  //         // window.scrollTo(0, 1)
  //         // $("html, body").stop().animate({
  //         //   scrollTop: 1
  //         // })
  //         // setTimeout(() => siteTitleLink.trigger("click"), 100)
  //         siteTitleLink.trigger("click")
  //       }
  //     },
  //     false
  //   )
  // }

  // content retrival and display -----------------------------------------------------------------------------------

  // menu and title navigation ------------------------------------------------------------------------------------------------
  menuNavLinks.add(siteTitleLink).on("click", function (e) {
    // menuNavLinks.on('click', function (e) {
    // console.log("current", currentContentID)

    const parentLi = $(this).parent()
    const isPage = parentLi.hasClass("menu-item-object-page")
    const isProject = parentLi.hasClass("menu-item-object-arbeit")
    const isArchive = parentLi.hasClass("menu-item-type-post_type_archive")
    const isCategory = parentLi.hasClass("menu-item-object-category")

    const id = getIDFromClasslist(parentLi)
    const isHome = id === homeID
    const onHomePage = currentContentID === homeID
    // console.log("wpse id", id, homeID, id === homeID)

    if (isPage || isProject || isCategory) {
      // links in the menu are either pages (info page )or project archive
      e.preventDefault()
      e.stopPropagation()

      let slug = $(this).attr("href").split(`${window.location.origin}/`)[1]
      if (isHome && onHomePage) {
        // if site title link and we are on the home page id==5
        // -> open the arbeiten archive
        slug = slug + "arbeiten/"
      }

      // console.log(slug)

      // history only gets updated when clicking on a different menu item, and not when navigating by url (gotolocation)
      const ref = `${window.location.origin}/${slug}`

      if (window.location.href !== ref) {
        window.history.pushState(null, null, ref)
        // console.log("updated history ", ref)

        // update the title tag when menu click. page load sets the title tag automatically (theme support 'title-tag')
        if (slug !== "") {
          // not home -> page | blogname
          document.title = $(this).text() + " | " + blogName
        } else {
          // is home -> blogname | blogdescription
          document.title = blogName + " | " + blogDescription
        }
      }

      if (isPage && !onHomePage) {
        openPageLink($(this))
      } else {
        // project
        if (isArchive || onHomePage || isCategory) openProjectArchive(slug)
        else {
          openProjectWithID(id)
        }
      }
    }
  })

  filterButton.on("click", function (e) {
    if (!navOpen) openNav()
    else closeNav()
  })

  // --------------------------------------------------------------------------------------------------------------------------------------------------------
  function openPageLink($link) {
    console.log("open page link", $link.attr("href"))
    // $("html, body").animate({
    //   scrollTop: 0
    // })

    const parent = $link.parent()

    const pageID = getIDFromClasslist(parent)

    // add menu to page
    siteContainer.addClass("start-container")
    siteContainer.removeClass("gallery-page")

    $("li.menu-item").removeClass("current-menu-item")
    parent.addClass("current-menu-item")

    openPageWithID(pageID)
  }

  function openPageWithID(pageID) {
    // console.log("open page with id", pageID)
    closeNav()
    // hide all info overlays
    closeInfoOverlay()
    $(".info-overlay").hide()

    if (pageID) {
      // hide all other page and post item content and the images
      if (pageID !== currentContentID) {
        $(".post-item-container").not(`#${pageID}`).hide() // hide page content
        $(".images-container__post-item-images").not(`#${pageID}-images`).fadeOut() // hide page images container
      }

      if (pageID === homeID) {
        siteContainer.addClass("page-home")
        contentContainer.hide()
      } else {
        siteContainer.removeClass("page-home")
        contentContainer.fadeIn()
      }

      setCurrentContentID(pageID)

      if ($(`div#${pageID}`).length == 0) {
        // post-item-container for this page not yet existing -> get the page
        // console.log("page not yet..", pageID, homeID === pageID ? "is home page" : "is page")
        // page container does not exist yet -> load page with page id

        // get page content
        $.getJSON(localized.root_url + "/wp-json/wp/v2/pages/" + pageID, page => {
          // console.log(page)

          // add page content ( no content for home page )
          postsContainer.append(`
            <div id="${pageID}" class="post-item-container post-type-page ${pageID === homeID ? "page-home " : "about-container "}initialized" 
            data-title="${page.title.rendered}" data-history="${page.slug}" >

            ${
              pageID !== homeID // home page has no content
                ? `
                <div class="post-item-content">    
                    ${page.content.rendered !== "" ? `<div class="inner-space">${page.content.rendered}</div>` : ``}
                    ${page.infos
                      .map(
                        info => `
                    <div class="inner-space">

                    ${
                      info.title
                        ? `
                        <div class="reading-title limited-width">${info.title}</div>
                        <div class="one-line-space"></div>
                        `
                        : ``
                    }
                    
                      <div class="${info.small ? "reading-text-small" : "reading-text "} limited-width">${info.content}</div>
                      <div class="one-line-space"></div>
                      <div class="one-line-space"></div>
                    </div>
                    `
                      )
                      .join("")}
                </div>`
                : ``
            }
            </div>
          `)

          const newPage = postsContainer.children(`#${pageID}`)
          newPage.hide()
          initPage(newPage) // get color data
          scrollTop(0, 0)

          // add load more posts functionality -> create LoadMorePosts object with news page id
          // loadMorePosts = new LoadMorePosts(postsPageID)
          // loadMorePosts.loadMorePosts()

          newPage.imagesLoaded(function () {
            // addLazyloadToElement(newPage)
            newPage.fadeIn()
          })
        })
      } else {
        // --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
        // page container already exists -> show the page
        // - either loaded by php page template, then we have to initialize the content first
        // - or already loaded before by ajax and is initialized
        // console.log("page exists", pageID)

        const page = $(`#${pageID}`)
        page.hide()
        scrollTop()

        if (!page.hasClass("initialized")) {
          // console.log("init existing page")
          // page not yet initialized because it was loaded by php

          if (pageID === homeID) {
            // console.log("is home!!!")
            // is page home
            page.imagesLoaded(function () {
              page.fadeIn()
              // no title fade in
            })
          } else {
            // all other pages
            initPage(page) // get colors
            page.imagesLoaded(function () {
              // console.log("page images loaded")
              page.fadeIn()
            })
          }

          page.addClass("initialized")
        } else {
          // page content existing and already initialized -> previously viewed
          // console.log("page already inited")
          page.fadeIn()
        }
      }
    }
  }

  function closeContent() {
    if (prevContentID !== -1) {
      // previous content exist -> browser back
      history.back()
    } else {
      // no previous content yet -> show home page
      siteTitleLink.trigger("click")
    }
  }

  function closePage() {
    // if (prevContentID !== -1) {
    //   // previous content exist -> browser back
    //   history.back()
    // } else {
    //   // no previous content yet -> show home page
    siteTitleLink.trigger("click")
    // }
  }

  // handler for project info toggle
  $(document).on("click", ".toggle-info-button.m-on", function (e) {
    e.preventDefault()
    e.stopPropagation()
    const infoText = $(this).siblings(".toggle-info-text")
    infoText.removeClass("show-info")

    // show info overlay, not info text
    const infoOverlay = $(this).siblings(".info-overlay")
    if (siteContainer.hasClass("info-overlay-open")) {
      closeInfoOverlay(infoOverlay)
    } else {
      openInfoOverlay(infoOverlay)
    }

    // if (infoText.hasClass("show-info")) {
    //   infoText.removeClass("show-info")
    // } else {
    //   $(".toggle-info-text").removeClass("show-info")
    //   infoText.addClass("show-info")
    // }
    // console.log("toggle info button mobile")
  })

  $(document).on("touchstart", ".toggle-info-button.m-on", function (e) {
    e.stopPropagation()
  })

  $(document).on("touchend", ".toggle-info-button.m-on", function (e) {
    e.stopPropagation()
  })

  // click on project picture in the gallery
  // open projects slider on desktop
  // disabled on mobile
  $(document).on("click", ".post-item-content__project.preview-space", function (e) {
    e.preventDefault()

    // update history
    const ref = $(this).attr("href") // .replace(/\/+$/, "")

    if (window.location.href !== ref) {
      window.history.pushState(null, null, ref)
      // console.log("updated history ", ref)

      // update the title tag when menu click. page load sets the title tag automatically (theme support 'title-tag')
      document.title = $(this).data("title") + " | " + blogName
    }

    // get current gallery swiper index
    // const swiper = $(this).find(".gallery-swiper.swiper-initialized")
    // let index = 0
    // if (swiper.length) {
    //   index = swiper[0].swiper.realIndex
    //   console.log("gallery swiper active index", index, swiper[0].swiper.activeIndex)
    // }
    const currentSlide = $(this).find(".swiper-slide-active")
    let index = 0
    if (currentSlide.length) {
      index = currentSlide.data("swiper-slide-index")
    }

    if (mobileView) {
      // make slider fullscreen
      // $(this).addClass("gallery-swiper-fullscreen")
      // gallerySwiperFullscreen = true
      openProjectWithID($(this).data("id"), index)
    } else {
      openProjectWithID($(this).data("id"), index)
    }
  })

  $(document).on("touchstart", ".preview-space", function (e) {
    $(".toggle-info-text").removeClass("show-info")
    // $(this).children(".toggle-info-text").addClass("show-info")
    // console.log("preview space touch start", e.touches[0])
    touchStartX = e.touches[0].clientX
    touchStartY = e.touches[0].clientY
  })

  $(document).on("touchmove", ".preview-space", function (e) {
    // console.log("preview space touch moved", e.touches[0].clientY)
    const x = e.touches[0].clientX
    const y = e.touches[0].clientY
    if (y > touchStartY + 5 || y < touchStartY - 5) {
      $(this).children(".toggle-info-text").addClass("show-info")
    }
  })

  $(document).on("touchend", ".preview-space", function (e) {
    $(this).children(".toggle-info-text").removeClass("show-info")
    // console.log("preview space touch end")
  })

  function openProjectWithSlug(slug) {
    // console.log("open project with slug", slug)

    // check if single project or archive and get the id
    $.getJSON(`${localized.root_url}/wp-json/pathtopost/v1/get?path=${slug}`, post => {
      // console.log(post)
      if (post.ID > 0) {
        // project found
        if (mobileView) {
          openProjectWithID(post.ID)
          // openProjectArchive(slug, post.ID)
        } else {
          openProjectWithID(post.ID)
        }
      } else {
        // is not a single project
        openProjectArchive(slug)
      }
    })
  }

  function openProjectWithID(projectID, initialSlide) {
    // if one project is opened all projects are loaded. but with image lazy loading
    if (projectID) {
      // console.log("open project with id", projectID, category, "slide ind", initialSlide)

      contentContainer.fadeIn()
      siteContainer.removeClass("page-home")
      // hide all other page and post items
      $(".post-item-container").not("projects-swiper").hide()
      siteContainer.removeClass("start-container") // not home page anyways
      siteContainer.removeClass("gallery-page")

      // $("#projects-swiper").fadeIn()

      setCurrentContentID("projects-swiper")

      // check if projects container exists already and create it if not
      let projectsSwiper = $("div#projects-swiper")

      if (projectsSwiper.length == 0) {
        // console.log("no projects swiper -> create projects swiper with all projects first")

        postsContainer.append(`
        <div id="projects-swiper" class="post-item-container swiper swiper-v fullheight">
          <div class="swiper-wrapper swiper-v-wrapper">
          </div>
          <div class="nav-button-up-down up"></div>
          <div class="nav-button-up-down down"></div>
        </div>
        `)
        projectsSwiper = $("div#projects-swiper")
        const swiperVWrapper = $(".swiper-v-wrapper")

        $.getJSON(localized.root_url + "/wp-json/arbeiten/v1/get", projects => {
          // console.log(projects)

          projects
            .map(project => {
              swiperVWrapper.append(`
                        
                            <div id="${project.id}" class="swiper-v-slide${project.id == projectID ? " current-project" : ""}${!category || (category && project.categories.includes(category)) ? " swiper-slide" : " noshow-swiper-slide"} ${project.categories}" data-title="${project.title}" data-history="${project.slug}/">
                              ${project.pictures.length ? `<div class="swiper swiper-h"><div class="swiper-wrapper swiper-h-wrapper">` : ``}                              
                              ${project.pictures
                                ?.map(
                                  (pic, index) => `
                                  <div class="swiper-slide swiper-h-slide">
                                    <img class="${index == 0 ? "swiper-v-lazy swiper-lazy" : "swiper-lazy"}"
                                      data-src="${pic.src}"
                                      data-srcset="${pic.srcset}"
                                      sizes="100vw"
                                      alt="${pic.alt}"
                                      style="width:calc(var(--x) * ${getImageCalcWidth(pic.width, pic.height)});">
                                      <div class="swiper-lazy-preloader"></div>
                                  </div>
                                  `
                                )
                                .join("")}
                              ${
                                project.pictures.length
                                  ? `
                              </div>
                                <div class="swiper-pagination numbertext"></div>
                                <div class="nav-button-left-right left"></div>
                                <div class="nav-button-left-right right"></div>
                              </div>`
                                  : ``
                              }
                              
                              <div id="infoText" class="btn-container top-left-relativ-btn">
                                  <div class="info-point"></div>
                              </div>

                              <div class="info-point-text">
                                <div>${project.data.projectinfo_1}</div>
                                <div class="italic">${project.data.projectinfo_2}</div>
                                <div>${project.data.projectinfo_3}</div>
                                <div>${project.data.projectinfo_4}</div>
                                <div>${project.data.projectinfo_5}</div>
                                <div class="text-level-3">
                                  <div>${project.data.fotoinfo_1}</div>
                                  <div>${project.data.fotoinfo_2}</div>
                                  <div>${project.data.fotoinfo_3}</div>
                                  <div>${project.data.fotoinfo_4}</div>
                                  ${project.data.link ? `<a href="${project.data.link.url}" target="${project.data.link.target}">${project.data.link.title ? project.data.link.title : project.data.link.url}</a>` : ``}
                                </div>
                              </div>

                              ${project.data.infos.length ? getInfoOverlay(project) : ""}
                                           
                          </div>
                        `)
            })
            .join("")

          initProjectSwiper($(`#${projectID}`))

          if (!projectsSwiper.hasClass("swiper-initialized") && !projectsSwiper.hasClass("one-slide")) {
            const index = projectsSwiper.find(".swiper-v-slide.current-project").index()
            // console.log("index", index)
            createProjectsSwiper(projectsSwiper, index)
            if (mobileView) setTimeout(() => disableProjectsSwiper(), 100)
          }
        })
      } else {
        // console.log("projects swiper exists already")

        if (!projectsSwiper.hasClass("swiper-initialized")) {
          // console.log("projects swiper not yet initialized")

          // set calc width for all images in the projects swiper

          projectsSwiper.find("img").each(function () {
            $(this).css("width", `calc(var(--x) * ${getImageCalcWidth($(this).data("width"), $(this).data("height"))})`)
          })

          initProjectSwiper(projectsSwiper.find(".swiper-v-slide.current-project"))

          if (!projectsSwiper.hasClass("one-slide")) {
            const index = projectsSwiper.find(".swiper-v-slide.current-project").index()
            // console.log("index", index)
            setTimeout(() => createProjectsSwiper(projectsSwiper, index), 500)
            if (mobileView) {
              setTimeout(() => {
                disableProjectsSwiper()
                $("#projects-swiper").fadeIn()
              }, 600)
            } else {
              $("#projects-swiper").fadeIn()
            }
          }
        } else {
          // console.log("projects swiper exists and initialized")
          noHistory = true
          syncProjectSwipers()

          showHideSwiperProjects(category, projectID)

          const slide = projectsSwiper.find(`#${projectID}:not('.swiper-slide-duplicate')`)
          if (slide.length) {
            // project slider exists
            const indx = slide.data("swiper-slide-index") // index of project in projects slider

            projectsSwiper[0].swiper.slideToLoop(indx, 0) // slide to project
            // console.log("projects swiper exists -> slide to ", indx)

            if (mobileView) disableProjectsSwiper()
          }
        }
      }
    }
  }

  function initProjectSwiper(projectSlide) {
    projectSlide.find(".swiper-h:not('.swiper-initialized')").each(function (index) {
      // get project id from from swiper-v-slide

      if (mobileView) {
        const id = $(this).parent().attr("id")
        const initialSlide = getActiveSlideFromGalleryForProject(id)

        createProjectSwiper($(this), initialSlide)
      } else {
        createProjectSwiper($(this))
      }
    })
  }

  function syncProjectSwipers() {
    // get all project swipers
    $(".swiper-h.swiper-initialized").each(function (index) {
      // get project id from from swiper-v-slide
      const id = $(this).parent().attr("id")
      const activeSlide = getActiveSlideFromGalleryForProject(id)

      if ($(this)[0].swiper) {
        $(this)[0].swiper?.slideToLoop(activeSlide, 0)
        // if (mobileView) {
        //   $(this)[0].swiper.params.speed = 400
        // } else {
        //   $(this)[0].swiper.params.speed = 800
        // }
        // $(this)[0].swiper.update()
      }
    })
  }

  function getActiveSlideFromGalleryForProject(id) {
    // check if gallery swiper for project exists
    let activeSlideIndex = 0
    const projectInGallery = $(`.post-item-content__project[data-id="${id}"]`)

    if (projectInGallery.length) {
      // console.log("project in gallery found!", id)
      const activeSlide = projectInGallery.find(".swiper-slide-active")
      if (activeSlide.length) {
        // console.log(`active slide for project-id ${id} is ${activeSlide.data("swiper-slide-index")}`)
        activeSlideIndex = activeSlide.data("swiper-slide-index")
      } else {
        // console.log("no active slide found")
      }
    } else {
      // console.log("project in gallery not found")
    }

    return activeSlideIndex
  }

  const getInfoOverlay = project =>
    `<div class="info-overlay">
    <div class="start-container">
      <div class="preview-space block">
        <div>${project.data.projectinfo_1}</div>
        <div class="italic">${project.data.projectinfo_2}</div>
        <div>${project.data.projectinfo_3}</div>
        <div>${project.data.projectinfo_4}</div>
        <div>${project.data.projectinfo_5}</div>
      </div>

      <div class="info-overlay__info">
        ${project.data.infos
          .map(
            info => `
          <div class="inner-space">

            ${
              info.titel
                ? `<div class="${info.klein ? `reading-text-small ` : `reading-text `} limited-width">
              ${info.titel}
            </div>
            <div class="one-line-space"></div>`
                : ``
            }
            
            <div class="${info.klein ? `reading-text-small ` : `reading-text `} limited-width">
              ${info.inhalt}
              <div class="one-line-space"></div>
            </div>
          </div>
        `
          )
          .join("")}
      </div>

    </div>
  </div>`

  function updateProjectsSwiper() {
    // console.log("projects swiper updated")
    const swiper = document.querySelector("#projects-swiper").swiper
    swiper.update()
  }

  function disableProjectsSwiper() {
    // console.log("projects swiper disabled")
    // const swiper = document.querySelector("#projects-swiper").swiper
    if (projectsSwiper) projectsSwiper.disable()
  }

  function enableProjectsSwiper() {
    // console.log("projects swiper enabled")
    // const swiper = document.querySelector("#projects-swiper").swiper
    if (projectsSwiper) projectsSwiper.enable()
  }

  function showHideSwiperProjects(category, projectID) {
    // console.log("show hide projects in the big projects swiper. filtered by category:", category)
    const swiper = $("#projects-swiper")

    if (swiper.length) {
      swiper[0].swiper.destroy()
      // find all projects (swiper-v-slide) slides in the projects slider
      // and add/remove them from the slider according to the category
      let current

      swiper.find(".swiper-v-slide").each(function () {
        $(this).removeClass("current-project") // class added from php

        if ($(this).hasClass(`cat-${category}`) || !category) {
          $(this).addClass("swiper-slide") // add to slider
          $(this).removeClass("noshow-swiper-slide") // remove from slider
          if ($(this).attr("id") === projectID) {
            // current project
            current = $(this)
          }
        } else {
          $(this).addClass("noshow-swiper-slide")
          $(this).removeClass("swiper-slide")
        }
      })

      if (current) {
        const index = swiper.find(".swiper-v-slide.swiper-slide").index(current)
        createProjectsSwiper(swiper)
      } else {
        createProjectsSwiper(swiper)
      }
    }
  }

  function closeProject() {
    // $(`#${projectID}`).hide()
    if (prevContentID !== -1) {
      // previous content exist -> browser back
      history.back()
    } else {
      // no previous content yet -> show home page
      siteTitleLink.trigger("click")
    }
  }

  function getMenuItemBySlug(slug) {
    // console.log("get menu item by slug:", slug, localized.root_url)
    let found = false
    let index = 0
    menuItems.each(function (ind) {
      if ($(this).children("a").attr("href") === `${localized.root_url}/${slug}`) {
        found = true
        index = ind
        // console.log("menu link found", $(this).children("a").attr("href"), index)
        return false
      }
    })
    if (found) {
      return menuItems.eq(index)
    } else return null
  }

  function setCurrentMenuItem(menuItem) {
    //
    menuItems.removeClass("current-menu-item") // remove current menu from all menu items
    menuItem.addClass("current-menu-item") // new current menu item
  }

  // ----------------------------------------------------------------------------------------------------------------------------------------------------------------------
  function openProjectArchive(slug, projectID) {
    if (slug) slug = slug.replace(/^\/+/, "") // remove leading /
    console.log("open the arbeiten archive", slug)

    contentContainer.fadeIn()
    siteContainer.removeClass("page-home")
    // hide all other page and post items
    let projectArchive = $("#project-archive")
    $(".post-item-container").not(projectArchive).hide()
    siteContainer.removeClass("start-container") // not home page anyways
    siteContainer.addClass("gallery-page")

    setCurrentContentID("project-archive")
    closeNav()
    // hide all info overlays
    closeInfoOverlay()

    $(".info-overlay").hide()

    let kat = ""
    let menuText = ""
    if (slug) {
      const menuitem = getMenuItemBySlug(slug)
      if (menuitem) {
        setCurrentMenuItem(menuitem)
        menuText = menuitem.children("a").text()
        // console.log("current menu item: ", menuText)
      }
      if (slug.includes("arbeiten/category/")) kat = slug.replace("arbeiten/category", "")

      if (kat !== "") {
        kat = kat.replaceAll("/", "")
        category = kat // store category slug
      } else {
        category = ""
      }
    }

    // console.log("menu text", menuText)
    document.title = menuText + " | " + blogName

    if (projectArchive.length == 0) {
      // no archive yet
      // console.log("no project archive yet")
      // localized.root_url + "/wp-json/wp/v2/arbeit?per_page=100&orderby=menu_order&order=asc"
      $.getJSON(localized.root_url + "/wp-json/arbeiten/v1/get", projects => {
        // console.log("arbeiten", projects)

        postsContainer.append(`
        <div id="project-archive" class="post-item-container post-type-archive gallery-container padding-top initialized" data-title="Projects" data-history="projects">
        
        ${projects
          .map(project => {
            // get random pic from pics with gallerie == true
            let randIndex
            let found = false
            let counter = 0
            do {
              randIndex = Math.floor(Math.random() * project.pictures.length)
              if (project.pictures[randIndex].gallerie) found = true
              counter++
            } while (!found && counter < 1000)

            // console.log("randIndex", randIndex)
            return `<a class="post-item-content__project preview-space align-items-center justify-content-center ${project.categories}" 
                                    href="${localized.root_url + "/arbeiten/" + project.slug}" data-id="${project.id}" data-slug="${project.slug}" data-title="${project.title}">
                                    
                                    <div class="gallery-swiper swiper swiper--lazy">
                                      <div class="swiper-wrapper">
                                        ${project.pictures
                                          ?.map(
                                            (pic, index) =>
                                              `<div class="swiper-slide ${index == randIndex ? `current-slide` : ``}">
                                              <img class="lazyload img-container img-only" 
                                              data-src="${pic.src}" 
                                              data-srcset="${pic.srcset}" 
                                              data-width="${pic.width}"
                                              data-height="${pic.height}"
                                              sizes="(max-width: 700px) calc(100vw - 40px), (max-width: 1200px) calc((100vw - 168px) / 3), calc((100vw - 192px) / 4)"
                                              alt="${pic.alt}"
                                              style="width:calc(var(--gx) * ${getImageCalcWidth(pic.width, pic.height)});">
                                              <div class="swiper-lazy-preloader"></div>
                                              </div>`
                                          )
                                          .join("")}
                                      </div>
                                    </div>
                                    
                                    ${
                                      project.data.infos.length
                                        ? `<div id="infoText" class="btn-container top-right-absolute-btn mobil-only toggle-info-button m-on">
                                        <div class="context-icon-verti">
                                            <div class="context-point-verti"></div>
                                            <div class="context-point-verti"></div>
                                            <div class="context-point-verti"></div>
                                        </div>
                                    </div>`
                                        : ``
                                    }


                                    <div class="toggle-info-text">
                                      <div>${project.data.projectinfo_1}</div>
                                      <div class="italic">${project.data.projectinfo_2}</div>
                                      <div>${project.data.projectinfo_3}</div>
                                      <div class="italic">${project.data.projectinfo_4}</div>
                                      <div>${project.data.projectinfo_5}</div>

                                    </div>

                                    ${project.data.infos.length ? getInfoOverlay(project) : ""}

                                  </a>`
          })
          .join("")}
                          </div>
                      `)

        projectArchive = $("#project-archive")
        initProjectArchive(projectArchive)

        // projectArchive.hide()
        // projectArchive.imagesLoaded(function () {
        const newProjects = projectArchive.find(".post-item-content__project")
        showHideProjects(newProjects, kat)
        addLazyloadToElement(projectArchive)
        // setTimeout(() => window.scrollTo(0, 1), 500)

        // console.timeEnd()
        // })
      })
    } else {
      // project archive exists already
      const projects = projectArchive.find(".post-item-content__project")
      // console.log(`project archive exists, ${projects.length} projects`)

      if (!projectArchive.hasClass("initialized")) {
        // console.log("init the project archive")
        // console.time()
        initProjectArchive(projectArchive)
        addLazyloadToElement(projectArchive)
        // projectArchive.hide()
        projectArchive.addClass("initialized")
        // projectArchive.imagesLoaded(function () {
        // console.log("archive images loaded")
        showHideProjects(projects, kat)
        projectArchive.fadeIn()
        // console.timeEnd()
        // })
      } else {
        syncProjectArchive(projectArchive)
        showHideProjects(projects, kat)
        projectArchive.fadeIn(() => {})
      }
      // setTimeout(() => window.scrollTo(0, 1), 500)
    }
  }

  function showHideProjects(projects, kat) {
    // console.log("show hide projects", kat)
    projects.hide()
    if (mobileView) {
      for (let i = 0; i < projects.length; i++) {
        const el = projects.eq(i)
        // console.log(el)
        if (kat == "" || el.hasClass(`cat-${kat}`)) {
          el.removeClass("noshow")
          el.fadeIn()
        }
      }
      restoreScrollPosition(1)
    } else {
      let count = 0

      for (let i = 0; i < projects.length; i++) {
        const el = projects.eq(i)
        // console.log(el)
        if (kat == "" || el.hasClass(`cat-${kat}`)) {
          setTimeout(function () {
            el.removeClass("noshow")
            el.fadeIn()
          }, count * 50)

          count++
        }
      }

      setTimeout(() => {
        restoreScrollPosition()
      }, count * 100)

      // projects.each(function (index) {
      //   const el = $(this)
      //   setTimeout(function () {
      //     if (kat == "" || el.hasClass(`cat-${kat}`)) {
      //       el.removeClass("noshow")
      //       el.fadeIn()
      //       count++
      //     } else {
      //       // el.hide()
      //     }
      //   }, index * 100)
      // })

      // console.log("found count", count)
    }
  }

  function getImageCalcWidth(width, height) {
    if (width > 0 && height > 0) {
      return Math.sqrt(2 / (width * height)) * width
    } else return 1
  }

  function initProjectArchive(projectArchive) {
    // console.time()
    projectArchive.find(".gallery-swiper").each(function () {
      // set calc image width
      $(this)
        .find("img")
        .each(function () {
          $(this).css("width", `calc(var(--gx) * ${getImageCalcWidth($(this).data("width"), $(this).data("height"))})`)
        })

      // get project id from parent data id
      const id = $(this).parent().data("id")
      let index = getActiveSlideFromDetailviewForProject(id)

      if (!index && index !== 0) {
        // detail view does not exists -> take the random index from the gallery images set in php with current-slide class

        // const randInd = Math.floor(Math.random() * $(this).find(".swiper-slide").length)
        index = $(this).find(".swiper-slide.current-slide").index()

        // console.log("gallery swiper inital slide, from php", index)
      } else {
        // console.log("gallery swiper inital slide, from detail view", index)
      }

      const swiper = new Swiper($(this)[0], {
        loop: true,
        mousewheel: {
          forceToAxis: true
        },
        initialSlide: index
        // lazy: {
        //   loadPrevNext: true,
        //   loadOnTransitionStart: true,
        //   checkInView: true
        // }
      })
    })
    // console.timeEnd()
  }

  function syncProjectArchive(projectArchive) {
    projectArchive.find(".gallery-swiper.swiper-initialized").each(function () {
      // get project id from parent data id
      const id = $(this).parent().data("id")
      let index = getActiveSlideFromDetailviewForProject(id)

      if (index >= 0) {
        // console.log("gallery swiper sync slide", $(this).parent().data("title"), index)

        const swiper = $(this)[0].swiper
        swiper.slideToLoop(index, 0)
      }
    })
  }

  function getActiveSlideFromDetailviewForProject(id) {
    // check if detail swiper for project exists
    let activeSlideIndex = null

    const projectSlideInProjectsSwiper = $(`#${id}.swiper-v-slide.swiper-slide`)

    if (projectSlideInProjectsSwiper.length) {
      // project found -> get active slide of project swiper
      activeSlideIndex = projectSlideInProjectsSwiper.find(".swiper-slide-active").data("swiper-slide-index")
    }

    return activeSlideIndex
  }

  function closeProjectArchive() {
    siteTitleLink.trigger("click") // to home page
  }

  function scrollToElement($this, time) {
    // console.log("scroll to element", $this.offset().top)
    time = time ?? navChangingTime
    autoScrolling = true
    $("html, body").animate(
      {
        scrollTop: $this.offset().top - adminBarHeight
      },
      time,
      function () {
        autoScrolling = false
      }
    )
  }

  function scrollTop(top, time) {
    top = top || 0
    time = time ?? navChangingTime
    autoScrolling = true
    // console.log("scrolltop", top)
    $("html, body")
      .add(contentContainer)
      .animate(
        {
          scrollTop: top
        },
        time,
        function () {
          autoScrolling = false
        }
      )
  }

  function restoreScrollPosition(ms) {
    const top = scrollYPositions[currentContentID] ?? 0
    const time = ms ?? 400
    scrollTop(top, time)
  }

  function initPage(page) {
    // const bgcolor = page.data("background-color")
    // console.log("page bgcolor is", bgcolor)
    // page.css("background-color", bgcolor)
    // const color = page.data("color")
    // console.log("page color is", color, "!")
    // page.css("color", color)
  }

  function addLazyloadToElement($elem) {
    // https://github.com/tuupola/lazyload
    // $("img.lazyload").lazyload();
    // lazyload();

    // const elem = document.querySelector(`[id="${elemID}"]`);
    const elem = $elem[0]
    const images = elem.querySelectorAll("img.lazyload")
    // console.log("lazyload images", images, " in container ", $elem);

    new Lazyload(images, {
      root: null,
      rootMargin: "50px",
      threshold: 0
    })

    // add load event listener for lazyload images
    $elem.find(`img.lazyload`).on("load", function () {
      // console.log("img on elem xx loaded", $elem.attr("id"))
      $(this).addClass("loaded") // add loaded class to image -> fade in with css opacity 1
      const preloader = $(this).next(".swiper-lazy-preloader")
      if (preloader.length) {
        preloader.remove()
      }
    })
  }

  // close post click handler
  // $(document).on("click", ".post-item-title__close", function (e) {
  //   siteTitleLink.trigger("click")
  // })

  infoButton.on("click", function () {
    if (siteContainer.hasClass("info-overlay-open")) {
      closeInfoOverlay()
    } else {
      openInfoOverlay()
    }
  })

  function openInfoOverlay(infoOverlay) {
    // console.log("open info overlay", currentContentID)
    siteContainer.addClass("info-overlay-open")

    if (currentContentID === "project-archive") {
      if (infoOverlay && infoOverlay.length) {
        infoOverlay.fadeIn()
      }
    } else {
      const currentProjectInfo = $("#projects-swiper").find(".swiper-v-slide.swiper-slide-active").children(".info-overlay")
      if (currentProjectInfo.length) currentProjectInfo.fadeIn()
      // disable swiping when ovelay is open
      $("#projects-swiper")[0]?.swiper?.disable()
    }
  }
  function closeInfoOverlay(infoOverlay) {
    siteContainer.removeClass("info-overlay-open")

    if (currentContentID === "project-archive") {
      if (infoOverlay && infoOverlay.length) {
        infoOverlay.fadeOut()
      }
    } else {
      const currentProjectInfo = $("#projects-swiper").find(".swiper-v-slide.swiper-slide-active").children(".info-overlay")
      if (currentProjectInfo.length) currentProjectInfo.fadeOut()
      $("#projects-swiper")[0]?.swiper?.enable()
    }
  }

  closeGoHome.on("click", e => {
    e.stopPropagation()
    // console.log("go home!", currentContentID)
    if (currentContentID === "project-archive") {
      // we're on the gallery page
      if (siteContainer.hasClass("info-overlay-open")) {
        closeInfoOverlay()
        $(".info-overlay").hide() // close all info overlays
      } else if (gallerySwiperFullscreen) {
        // for mobile -> back to gallery
        $(".post-item-content__project.gallery-swiper-fullscreen").removeClass("gallery-swiper-fullscreen")
      } else {
        siteTitleLink.trigger("click")
      }
    }
    // wenn in project detail ansicht == projects swiper -> back to gallery
    // oder close info-overlay wenn offen
    else {
      // if (currentContentID === "projects-swiper")
      if (siteContainer.hasClass("info-overlay-open")) {
        closeInfoOverlay()
      } else {
        // -> back to gallery

        $(".info-overlay").hide() // close all info overlays
        closeInfoOverlay()

        let ref = ""
        if (!category) ref = `${window.location.origin}/arbeiten/`
        else ref = `${window.location.origin}/arbeiten/category/${category}/`

        if (window.location.href !== ref) {
          window.history.pushState(null, null, ref)
          // console.log("updated history ", ref)

          // update the title tag when menu click. page load sets the title tag automatically (theme support 'title-tag')
          if (!category) document.title = "Arbeiten | " + blogName
          else document.title = `${titleCase(category.replace("-", " "))} | ${blogName}`
        }
        if (!category) openProjectArchive("arbeiten/")
        else openProjectArchive(`arbeiten/category/${category}/`)
      }
    }
  })

  function titleCase(str) {
    var splitStr = str.toLowerCase().split(" ")
    for (var i = 0; i < splitStr.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you
      // Assign it back to the array
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
    }
    // Directly return the joined string
    return splitStr.join(" ")
  }

  function openNav() {
    // console.log("open nav")
    $html.addClass("nav-open")
    navContainer.addClass("site-menu--nav-open")
    navOpen = true
  }

  function closeNav() {
    $html.removeClass("nav-open")
    navContainer.removeClass("site-menu--nav-open")
    navOpen = false
  }

  // init vertical projects (alle arbeiten) swiper
  function createProjectsSwiper($swiper, initialSlide) {
    // console.log("create the big projects slider", initialSlide)
    noHistory = true

    projectsSwiper = new Swiper($swiper[0], {
      slidesPerView: 1,
      spaceBetween: 0,
      // mousewheel: true, // default = true
      // allowTouchMove: true, // default = true
      mousewheel: {
        forceToAxis: true,
        invert: false,
        thresholdDelta: 20,
        thresholdTime: 20,
        sensitivity: 0.1
      },
      loop: true,
      loopPreventsSlide: true,
      init: true,
      speed: 800,
      simulateTouch: false,
      // history: {
      //   root: localized.root_url,
      //   key: "arbeiten",
      //   keepQuery: true,
      //   replaceState: true
      // },
      initialSlide: initialSlide ? initialSlide : 0,
      direction: "vertical",
      observer: true,
      observeSlideChildren: true,
      // Disable preloading of all images
      preloadImages: false,
      // Enable lazy loading
      lazy: {
        loadPrevNext: false,
        loadOnTransitionStart: true,
        checkInView: true,
        elementClass: "swiper-v-lazy"
      },
      // keyboard: {
      //   enabled: true,
      //   pageUpDown: true
      // },
      keyboard: {
        enabled: true
      },
      navigation: {
        nextEl: ".nav-button-up-down.down",
        prevEl: ".nav-button-up-down.up"
      },
      on: {
        init: function (swiper) {
          // console.log("projects swiper initialized")
          noHistory = false // transition end comes before init

          // swiper.params.history = {
          //   root: localized.root_url,
          //   key: "arbeiten",
          //   keepQuery: true,
          //   replaceState: false
          // }
        },

        beforeTransitionStart: function (swiper) {
          // console.log("before transition start")
          const swiperH = $(".swiper-v-slide.swiper-slide-active > .swiper-initialized")
          if (swiperH.length) {
            if (!swiperH[0].swiper) {
              // console.log("before transition start: no project swiper -> recreate swiper", swiperH.parent().data("history"))

              // get current index from duplicate swiper
              const dup = $(".swiper-v-slide.swiper-slide-duplicate-active > .swiper-initialized")
              if (dup && dup[0].swiper) {
                const indx = dup[0].swiper.realIndex
                createProjectSwiper(swiperH, indx)
              }
            } else {
              // check if image of active slide is already loaded
              // add lazy load if not
              const img = swiper.$el.find(".swiper-v-slide.swiper-slide-active").find(".swiper-h-slide.swiper-slide-active > img")
              if (img.length) {
                // console.log("active image found...", img[0].src, img.attr("src"))
                const src = img.attr("src")
                if (!src) {
                  // add lazy load of vertical swiper to the image
                  img.addClass("swiper-v-lazy")
                  // console.log("lazy load v vv  added")
                }
              }
            }
          }
          // close info overlay
          closeInfoOverlay()
        },
        transitionEnd: function (swiper) {
          const activeSlide = $(swiper.$el).find(".swiper-v-slide.swiper-slide-active")
          // console.log("all projects swiper transition end", activeSlide)

          //  init project slider if not initialized yet
          const pSwiperNotInit = activeSlide.find(".swiper-h:not('.swiper-initialized')")
          if (pSwiperNotInit.length) {
            initProjectSwiper(activeSlide)
          }

          const info = activeSlide.children(".info-point-text")
          info.fadeIn()
          // update history manually (swiper js history on first slide sometimes updated???)
          // update history
          if (!noHistory) {
            const ref = `${window.location.origin}/arbeiten/${activeSlide.data("history")}`

            if (window.location.href !== ref) {
              window.history.pushState(null, null, ref)
              // console.log("updated history ", ref)

              // update the title tag when menu click. page load sets the title tag automatically (theme support 'title-tag')
              document.title = activeSlide.data("title") + " | " + blogName
            }
          }

          // show / hide info button for overlay if no info overlay
          if (activeSlide.children(".info-overlay").length) {
            infoButton.removeClass("hidden")
          } else {
            infoButton.addClass("hidden")
          }
        }
      }
    })
  }

  // $(".nav-button-up-down").on("click", () => {
  //   console.log("up down click")
  //   projectsSlider.slideNext()
  // })

  // init single arbeit swiper
  function createProjectSwiper($swiper, initialSlide) {
    let speed = mobileView ? 400 : 800

    const swiper = new Swiper($swiper[0], {
      slidesPerView: 1,
      spaceBetween: 0,
      slidesPerGroup: 1,
      grabCursor: false,
      loop: true,
      loopPreventsSlide: true,
      init: true,
      // observer: true,
      // observeParents: true,
      speed: speed,
      simulateTouch: false,
      initialSlide: initialSlide ? initialSlide : 0,
      // Disable preloading of all images
      preloadImages: false,
      // Enable lazy loading
      lazy: {
        loadPrevNext: true,
        loadOnTransitionStart: true,
        checkInView: true
        // scrollingElement: ".swiper-v"
      },
      mousewheel: {
        forceToAxis: true,
        invert: false,
        thresholdDelta: 20,
        thresholdTime: 20,
        sensitivity: 0.1
      },
      keyboard: {
        enabled: true
      },
      pagination: {
        el: ".swiper-pagination",
        type: "fraction"
      },
      navigation: {
        nextEl: ".nav-button-left-right.right",
        prevEl: ".nav-button-left-right.left"
      },
      on: {
        init: function (swiper) {
          // console.log("project swiper initialized", initialSlide)
          // add swiper v lazy load to active image
          const img = $swiper.find(".swiper-slide-active > img")
          img.addClass("swiper-v-lazy")
          // setTimeout(() => {
          //   swiper.slideToLoop(initialSlide)
          // }, 1500)
        },

        transitionStart: function (swiper) {
          // console.log("project swiper transition start", swiper.realIndex)
          if (swiper.realIndex === 0) {
            $(swiper.$el).parent().children(".info-point-text").fadeIn()
          } else {
            $(swiper.$el).parent().children(".info-point-text").fadeOut()
          }
        },

        transitionEnd: function (swiper) {
          // console.log("project swiper transition end: ", swiper.$el.parent().hasClass("swiper-slide-active"))

          // if active slide -> check if duplicate active exists and snyc it
          if (swiper.$el.parent().hasClass("swiper-slide-active")) {
            // sync duplicate active slide
            const dup = $(".swiper-v-slide.swiper-slide-duplicate-active")
            if (dup.length) {
              // console.log("duplicat active found")
              const $swiperH = dup.children(".swiper-initialized")
              if ($swiperH.length) {
                const swiperH = $swiperH[0].swiper
                if (swiperH) {
                  swiperH.slideToLoop(swiper.realIndex)
                } else {
                  // console.log("no project swiper ume...")
                  createProjectSwiper($swiperH, swiper.realIndex)
                }
              }
            }
          }
        }
      }
    })
  }

  // toggle info for project info in swiper
  $(document).on("click", "#infoText", function (e) {
    $(this).siblings(".info-point-text").fadeToggle()
  })

  // handle pathname ------------------------------------------------------------------------------------------------
  gotoLocation(pathname)

  function gotoLocation(pathname) {
    // console.log("goto to location:", pathname)

    // get post info
    if (pathname === "/") {
      // is home page
      // console.log("is home")
      openPageLink(siteTitleLink)
      // --------------------------------------------------------
    } else if (pathname.startsWith("/arbeiten/")) {
      openProjectWithSlug(pathname)
    } else {
      // not home nor project
      // console.log("not home check menu items", $("li.menu-item").length)
      // first check if it's a menu item. exclude the lang menu item
      let found = false
      $("li.menu-item:not(.lang-item)").each(function () {
        if ($(this).children("a").attr("href").includes(pathname)) {
          found = true
          // console.log("menu link found", $(this).children("a").attr("href"))
          // if page
          if ($(this).hasClass("menu-item-object-page")) openPageLink($(this).children("a"))
          // if project
          else if ($(this).hasClass("menu-item-object-page")) openProjectWithSlug(pathname)
          return false
        }
      })
      if (!found) {
        // was not a menu link
        // console.log("not menu link")
        // something other -> get post type for path
        $.getJSON(`${localized.root_url}/wp-json/pathtopost/v1/get?path=${pathname}`, post => {
          // console.log(post)
          if (post.ID > 0) {
            // post found
            if (post.post_type == "page") {
              // page that's not in the menu
              openPageWithID(post.ID)
              // // hide all other page and post items
              // $(".post-item-container").not(`#${pageID}`).hide()
              // $("li.menu-item").removeClass("current-menu-item")
              // $(`#${pageID}`).fadeIn()
            } else if (post.post_type == "post") {
            } else if (post.post_type == "arbeit") {
              openProjectWithID(post.ID)
            } else {
              // hide all other post items
              $(".post-item-container").not(`#${post.ID}`).hide()
              $("li.menu-item").removeClass("current-menu-item")
              $(`#${post.ID}`).fadeIn()
            }
          } else {
            // is not a post
            // hide all other post items
            $(".post-item-container").fadeIn()
            $("li.menu-item").removeClass("current-menu-item")
          }
        })
      }
    }
  }

  //
  function setCurrentContentID(id) {
    prevContentID = currentContentID
    currentContentID = id
    // console.log("current content:", currentContentID, prevContentID)
  }

  let resizeTimeout = null
  let resizeTimeout2 = null

  window.addEventListener("resize", () => {
    checkMobileView()
    calcVhPropertyFromClientHeight()

    clearTimeout(resizeTimeout)
    resizeTimeout = setTimeout(() => {
      touchSupport()
      onOrientationChange()
      setAdminBarHeight()
      calcVhPropertyFromClientHeight()
      navAndTitleHeight()
      checkMobileView()
      navAndTitleHeight()
      projectsSwiperOnOff()
    }, 500)

    clearTimeout(resizeTimeout2)
    resizeTimeout2 = setTimeout(() => {
      checkMobileView()
      // for ios safari to get correct window height
      calcVhPropertyFromClientHeight()
    }, 1000)
  })

  function touchSupport() {
    const touchsupport = "ontouchstart" in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0

    if (!touchsupport) {
      // browser doesn't support touch
      // document.documentElement.className += " non-touch"
      $("html").addClass("non-touch")
      $("html").removeClass("touch")
    } else {
      $("html").removeClass("non-touch")
      $("html").addClass("touch")
    }
  }

  function checkMobileView() {
    if ($(window).width() < mobileBreakpoint) {
      mobileView = true
      $("html").addClass("mobile-view")
      $("html").removeClass("desktop-view")
    } else {
      mobileView = false
      $("html").removeClass("mobile-view")
      $("html").addClass("desktop-view")
    }
    // console.log("window width", $(window).width());
  }

  function projectsSwiperOnOff() {
    if (mobileView) {
      disableProjectsSwiper()
      $(".swiper-h.swiper-initialized").each(function (index) {
        if ($(this)[0].swiper) {
          $(this)[0].swiper.params.speed = 400
          $(this)[0].swiper.update()
        }
      })
    } else {
      enableProjectsSwiper()
      $(".swiper-h.swiper-initialized").each(function (index) {
        if ($(this)[0].swiper) {
          $(this)[0].swiper.params.speed = 800
          $(this)[0].swiper.update()
        }
      })
    }
  }

  function onOrientationChange() {
    // detect orientation change
    if ((orientationLandscape && window.innerWidth < window.innerHeight) || (!orientationLandscape && window.innerWidth >= window.innerHeight)) {
      setLandscape()
    }
  }

  function setLandscape() {
    // store new orientation
    orientationLandscape = window.innerWidth >= window.innerHeight

    if (orientationLandscape) {
      $("html").addClass("orientation-landscape")
      $("html").removeClass("orientation-portrait")
    } else {
      $("html").removeClass("orientation-landscape")
      $("html").addClass("orientation-portrait")
    }
    // console.log("orientation changed, landscape:", orientationLandscape);
  }

  function setAdminBarHeight() {
    let wpabh = 0
    const adminBar = $("#wpadminbar")
    if (adminBar.length) {
      wpabh = adminBar.outerHeight()
    }
    adminBarHeight = wpabh
    $(":root").css("--adminBarHeight", `${adminBarHeight}px`)
  }

  function navAndTitleHeight() {
    siteTitleHeight = siteTitle.outerHeight()
    $(":root").css("--siteTitleHeight", `${siteTitleHeight}px`)
    // console.log("title height", siteTitleHeight)

    // get nav height only when nav closed
    if (!navOpen) {
      navHeight = navContainer.outerHeight()
      $(":root").css("--navHeight", `${navHeight}px`)
    }
  }

  function calcVhPropertyFromClientHeight() {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    const vh = window.innerHeight * 0.01
    // console.log("height: ", vh * 100);
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`)
  }

  // reload page on broswer back / forward
  window.onpopstate = function (event) {
    // console.log("on popstate", event);
    gotoLocation(window.location.pathname)
  }

  document.addEventListener("scroll", function (e) {
    const currentScroll = window.pageYOffset

    scrollYPositions[currentContentID] = currentScroll
    // console.log(scrollYPositions)
  })

  function getIDFromClasslist(elem) {
    // get page id from menu item classlist (wpse-object-id-XX)
    let classlist = elem.attr("class").split(/\s+/)
    let pageID
    $.each(classlist, function (index, item) {
      if (item.startsWith("wpse-object-id-")) {
        const splits = item.split("wpse-object-id-")
        if (splits.length > 1) pageID = splits[1]
        return false
      }
    })
    return pageID
  }

  function checkFlexGap() {
    // create flex container with row-gap set
    var flex = document.createElement("div")
    flex.style.display = "flex"
    flex.style.flexDirection = "column"
    flex.style.rowGap = "1px"
    flex.style.position = "absolute"

    // create two, elements inside it
    flex.appendChild(document.createElement("div"))
    flex.appendChild(document.createElement("div"))

    // append to the DOM (needed to obtain scrollHeight)
    document.body.appendChild(flex)
    var isSupported = flex.scrollHeight === 1 // flex container should be 1px high from the row-gap
    flex.parentNode.removeChild(flex)

    return isSupported
  }

  function observeElement($this) {
    if (observer) {
      observer.disconnect()
    } else {
      observer = new window.IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            // console.log("ENTER")
          } else {
            // console.log("LEAVE")
          }
        },
        {
          root: null,
          threshold: 0.01 // set offset 0 means trigger if atleast 0% of element in viewport
        }
      )
    }
    observer.observe($this[0])
  }
})
